import $ from "jquery";
$(document).ready(function() {
   $("body").on("click","#search__article",function (e) {
      e.preventDefault();

      let refArticle = $(this).parents("form").find("input[type='text']").val();

      $.post("adm-majArticleSearchResult.php",{ref_art: refArticle}, function(data) {
         $("#searchResultDiv").html(data);
      })

   });



    $("body").on("click","#update-article",function (e) {
       e.preventDefault();
       let data = $(this).parents("form").serialize();
        $("#updateResult").html("<img src='/images/loader.gif' alt='loader' />");
       $.post("adm-majArticleUpdate.php",data, function(response) {
            $("#updateResult").html(response);
       });
    })
});
